import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
    this.resetError = this.resetError.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
  }

  resetErrorAndNavigate = () => {
    this.resetError();
    if (this.props.navigate) {
      this.props.navigate("/home");
    } else {
      console.error("Navigate function not provided as prop");
    }
  };

  resetError() {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col gap-5 justify-center items-center h-screen w-screen bg-[#000]">
          <div className="flex flex-col gap-6 justify-center items-center">
            <h1 className="hero text-[#fff] text-[6rem] font-bold font-hero leading-[100%] tracking-tighter  pb-10">
              Oops!
            </h1>
            <div className="px-2 text-[#fff]/80 text-lg md:text-xl self-center max-w-screen-md text-center">
              Yikes! My code just went rogue like a rebellious teenager. Time to
              lay down the law and get it back in line!
            </div>
          </div>
          <div className="mt-8 mb-16">
            <button
              className="min-w-24 h-12 border-2 text-[#fff] rounded-md"
              onClick={this.resetErrorAndNavigate}
            >
              Home
            </button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
