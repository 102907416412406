import Hero from "./Hero";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-5 justify-center items-center">
      <Hero
        hero={["Whoops!"]}
        hideStar={true}
        description={
          "Lost in the web maze? Fear not! Let's reroute and get you back pronto!"
        }
      />
      <div className="mt-8 mb-16">
        <Button
          onClick={() => {
            navigate("/home");
          }}
          text="Home"
        />
      </div>
    </div>
  );
};

export default Error404;
