import { useState } from "react";
import useMediaQuery from "../Hooks/useMediaQuery";

const Card = ({ cardColor, content }) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <div
      style={{ background: cardColor }}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.stopPropagation();
          window.open(content.live, "_blank");
        }
      }}
      aria-label={`${content.name} - Live`}
      onMouseEnter={(_) => setIsHovering(true)}
      onMouseLeave={(_) => setIsHovering(false)}
      className={`relative overflow-clip w-full min-h-[28rem] transition ease-in duration-300 rounded-[4rem] cursor-pointer ${
        isHovering && "shadow-2xl -translate-y-2"
      }`}
      onClick={(_) => window.open(content.live, "_blank")}
    >
      <div className="flex flex-col justify-start absolute top-2 right-2 m-10 items-end">
        <div className="font-hero text-4xl text-light-secondary-text text-end">
          {content.name}
        </div>
        <div className="font-body tracking-widest">
          {content.type.toUpperCase()}
        </div>
        {content.github && (
          <button
            type="button"
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            onClick={(_) => window.open(content.github, "_blank")}
            className="gap-3 mt-3 font-body p-1 px-3 border-2 border-light-secondary-text rounded-md cursor-pointer opacity-70 hover:opacity-100 transition ease-in duration-300"
          >
            {"Github"}
          </button>
        )}
      </div>
      <div
        className={`absolute transition ease-in duration-300 w-full right-[-20%] top-1/2 ${
          isHovering && "translate-y-4"
        }`}
      >
        {content.comp}
      </div>
    </div>
  );
};

const CardLayout = ({ left = 40, right = 60, cardColor, contents }) => {
  const isBelowBreakpoint = useMediaQuery(1024);
  return (
    <div className="md:p-14 p-8 flex lg:flex-row flex-col gap-16 w-screen h-auto">
      <div style={{ width: isBelowBreakpoint ? "100%" : `${left}%` }}>
        <Card cardColor={cardColor[0]} content={contents[0]} />
      </div>
      <div style={{ width: isBelowBreakpoint ? "100%" : `${right}%` }}>
        <Card cardColor={cardColor[1]} content={contents[1]} />
      </div>
    </div>
  );
};

export default CardLayout;
