import Hero from "./Hero";
import useSlideAnimation from "../Hooks/useSlideAnimation";
import useMediaQuery from "../Hooks/useMediaQuery";

const PlanCard = ({ number, title, content }) => {
  return (
    <div className="flex flex-col gap-2 w-full text-left">
      <div className="font-hero text-4xl opacity-50 text-light-primary-text dark:text-dark-primary-text">
        {number}
      </div>
      <h3 className="hero3 font-hero mt-6 text-light-primary-text dark:text-dark-primary-text">
        {title}
      </h3>
      <div className="font-body text-md text-light-secondary-text dark:text-dark-secondary-text">
        {content}
      </div>
    </div>
  );
};

const ExperienceCard = ({ width, companyName, role, duration }) => {
  const breakPoint = useMediaQuery(768);
  return (
    <div
      style={{ width: !breakPoint ? width : "100%" }}
      className={`h-auto py-3 border-2 border-light-primary-text dark:border-none dark:bg-dark-primary-text text-light-primary-text dark:text-[#000] self-center rounded-full flex flex-col items-center justify-center gap-2`}
    >
      <div className="font-hero font-bold text-3xl">{companyName}</div>
      <div
        className="flex flex-row gap-2 font-body text-sm opacity-75"
        style={{ fontSize: "clamp(1rem, .9617rem + .1633vw, 1.125rem)" }}
      >
        <div>{role}</div>
        <div>•</div>
        <div>{duration}</div>
      </div>
    </div>
  );
};

const Work = () => {
  return (
    <div className="w-full my-10 h-auto flex flex-col gap-1 px-6 md:px-0">
      <ExperienceCard
        width="70%"
        companyName="JioCinema"
        role="SDE 2"
        duration="2023-Present"
      />
      <ExperienceCard
        width="50%"
        companyName="JUSPAY"
        role="Frontend Engineer 2"
        duration="2021-2023"
      />
      <ExperienceCard
        width="30%"
        companyName="Betaflux"
        role="Intern"
        duration="2020~"
      />
    </div>
  );
};

const Img = ({ src, alt, showFull }) => {
  const breakPoint = useMediaQuery(1114);
  return (
    <img
      height={480}
      width={112}
      className={`h-[30rem] rounded-xl w-28 ${
        !breakPoint ? "hover:w-[50rem]" : ""
      } ${
        breakPoint && showFull && "w-[50rem]"
      } transition-all duration-500 ease-in-out object-cover`}
      src={`/${src}`}
      fetchPriority="high"
      alt={alt}
    />
  );
};

const Gallery = () => {
  return (
    <div className=" my-24 flex flex-row gap-3 justify-center items-center overflow-clip ">
      <Img src={"coorg-view.jpg"} alt={"coorg-view"} />
      <Img
        src={"coffee-plantation.jpg"}
        alt={"coffee-plantation"}
        showFull={true}
      />
      <Img src={"me.jpg"} alt={"myself"} />
    </div>
  );
};

const About = () => {
  let heroAnimation = useSlideAnimation(0.2, "big");
  let sub1 = useSlideAnimation(0.4, "small");
  let sub2 = useSlideAnimation(0.6, "small");
  return (
    <div className="flex flex-col gap-2 justify-center items-center px-0 lg:px-20">
      <Hero hero={["Hello World!"]} hideStar={true} />
      <div className="flex md:flex-row flex-col gap-10 md:gap-24 mt-6 md:mt-14 w-[100%] items-center place-content-center">
        <div
          style={heroAnimation}
          className=" flex flex-col justify-center items-center w-full md:w-[30%]"
        >
          <div className="relative h-[18.25rem] text-[#ffbf73]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-[20rem] stroke-[#000000] dark:stroke-[#ffffff] overflow-visible"
              fill="currentColor"
              viewBox="0 0 24 24"
              stroke="black"
              strokeWidth={0.2}
              strokeLinecap="round"
            >
              <path d="M24 22h-24l12-20z" />
            </svg>
            <img
              src="/bops-hero.png"
              width={250}
              height={250}
              className="left-[13%] z-30 absolute bottom-0"
              fetchPriority="high"
              alt="hero-img"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center z-30 w-full md:w-[50%] px-8 md:px-2">
          <h2
            style={heroAnimation}
            className="hero2 text-light-primary-text dark:text-dark-primary-text leading-[110%] font-bold font-hero flex justify-center text-left"
          >
            I'm a Software Enginner working from 24°C Bengaluru, India.
          </h2>
          <p
            style={sub1}
            className="font-body text-light-secondary-text dark:text-dark-secondary-text mt-14"
          >
            Hi, I'm Dilan Bopanna. Throughout the last three years, I've delved
            into diverse realms encompassing web development, front-end design,
            and native applications. Embracing versatility, I've taken on
            numerous roles.
          </p>
          <p
            style={sub2}
            className="font-body text-light-secondary-text dark:text-dark-secondary-text mt-8"
          >
            Currently, I'm employed as a Software Development Engineer 2 at
            JioCinema. My focus revolves around enhancing user experience and
            optimizing website performance.
          </p>
        </div>
      </div>
      <div className="h-auto w-full bg-light-primary dark:bg-dark-primary  my-20 mt-28 lg:rounded-[6rem] p-16 md:p-20 lg:p-40 flex flex-col gap-16 md:gap-24">
        <div className="w-full flex flex-col md:flex-row gap-16 lg:gap-28 h-auto">
          <PlanCard
            number="01"
            title="Define"
            content="Initially, I meticulously analyze requirements, set achievable goals, and craft a comprehensive plan with clear timelines and resource allocations, ensuring seamless execution and effective risk management."
          />
          <PlanCard
            number="02"
            title="Design"
            content="In this critical phase, I architect the project, conceptualize intuitive user interfaces, and outline efficient algorithms, prioritizing scalability and maintainability throughout the development process."
          />
        </div>
        <div className="w-full flex flex-col md:flex-row gap-16 md:gap-28 h-auto">
          <PlanCard
            number="03"
            title="Develop"
            content="Diving into coding, I meticulously implement features, rigorously test components, and collaborate closely with team members to integrate our efforts into a cohesive software solution."
          />
          <PlanCard
            number="04"
            title="Deploy"
            content="Overseeing the release process, I conduct exhaustive testing, solicit valuable feedback, and promptly incorporate updates to uphold the software's relevance and ensure smooth post-deployment operation and support."
          />
        </div>
      </div>
      <Work />
      <div className="flex flex-col justify-center items-center z-30 w-full md:w-[50%] px-8 md:px-2">
        <p className="font-body text-light-secondary-text dark:text-dark-secondary-text mt-14">
          Born and raised amidst the lush coffee plantations of Coorg, I imbibed
          a deep appreciation for nature and exploration. School days were spent
          amidst the rich aroma of coffee beans, nurturing my love for travel
          and discovery.
        </p>
        <p className="font-body text-light-secondary-text dark:text-dark-secondary-text mt-8">
          My journey into the software world began unexpectedly, spurred by a
          remarkable performance in a computer science course. Intrigued by the
          exponential technological revolution of our times, I pursued
          engineering in computer science. From building basic websites to
          crafting intricate digital solutions, my passion for web development
          has endured, fueled by the endless possibilities the web offers.
        </p>
        <p className="font-body text-light-secondary-text dark:text-dark-secondary-text mt-8">
          Outside the realm of work, I find solace in embracing life's simple
          pleasures. Whether it's embarking on spontaneous adventures or
          savoring quiet moments amidst nature's bounty, I cherish the balance
          between my professional endeavors and personal joys.
        </p>
      </div>
      <Gallery />
    </div>
  );
};
export default About;
