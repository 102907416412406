import React, { useState, useEffect } from "react";
import PageLoader from "./PageLoader";
import Error404 from "./404";
import CodeView from "./CodeView";
import useSlideAnimation from "../Hooks/useSlideAnimation";

const Note = ({ text }) => {
  return (
    <aside
      style={{ fontSize: "clamp(.875rem, .7985rem + .3265vw, 1.125rem)" }}
      className="border-l-[0.3rem] border-light-primary-text dark:border-dark-primary-text mx-5 md:mx-0 px-7  py-5 bg-[#ffe7cb78] dark:bg-dark-backdrop rounded-r-xl mt-10 "
    >
      {text}
    </aside>
  );
};

const renderContent = (item, index) => {
  switch (item.type) {
    case "paragraph":
      return (
        <p
          style={{ fontSize: "clamp(1rem, .8852rem + .4898vw, 1.375rem)" }}
          className="px-10 mt-8"
          key={index}
        >
          {item.content}
        </p>
      );
    case "image":
      return (
        <figure className="mt-10 mx-5 md:mx-0" key={index}>
          <img className="rounded-2xl" src={item.src} alt={item.alt} />
          {item.caption && (
            <figcaption className="text-sm italic">{item.caption}</figcaption>
          )}
        </figure>
      );
    case "heading":
      return (
        <h2
          className="font-hero mt-16 px-10"
          style={{ fontSize: "clamp(1.5rem, 1.0982rem + 1.7143vw, 2.8125rem)" }}
          key={index}
        >
          {item.content}
        </h2>
      );
    case "codeSnippet":
      return <CodeView code={item.content} key={index} />;
    case "note":
      return <Note text={item.content} />;
    default:
      return null;
  }
};

const BlogRenderer = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const titleslideIn = useSlideAnimation(0, "big");
  const metaSlideIn = useSlideAnimation(0.2, "big");

  useEffect(() => {
    const getLastPathPart = (url) => {
      const paths = url.pathname.split("/").filter(Boolean);
      return paths[paths.length - 1];
    };

    const loadData = async () => {
      try {
        const path = getLastPathPart(window.location);
        const response = await fetch(`/${path}.json`);
        if (!response.ok) {
          throw new Error(`Failed to load ${path}.json`);
        }
        const jsonData = await response.json();
        setPost(jsonData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <Error404 />;
  }

  return (
    <article className="font-body flex flex-col w-full md:w-7/12 items-center justify-center">
      <h1
        className="font-hero text-light-primary-text dark:text-dark-primary-text"
        style={{
          fontSize: "clamp(1.875rem, 1.301rem + 2.449vw, 3.95rem)",
          ...titleslideIn,
        }}
      >
        {post.title}
      </h1>
      <div
        style={metaSlideIn}
        className="flex flex-row text-base gap-2 text-light-primary-text dark:text-dark-primary-text mt-14 items-center justify-center mb-8"
      >
        <img
          className="h-8 w-8 rounded-full mr-3 outline-1 outline outline-offset-1 outline-light-primary-text dark:outline-dark-primary-text"
          src="/author.png"
          alt="author"
        />
        <div>{post.author}</div>
        <div>•</div>
        <div>{post.lastUpdatedDate}</div>
      </div>
      <div className="text-light-primary-text dark:text-dark-primary-text mb-20 mt-10 w-full">
        {post.content.map(renderContent)}
      </div>
    </article>
  );
};

export default BlogRenderer;
