import Header from "./Header";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import Blog from "./Components/Blog";
import Error404 from "./Components/404";
import ChatBot from "./Components/ChatBot";
import About from "./Components/About";
import BlogRenderer from "./Components/BlogRenderer";
import { useEffect } from "react";

function ScrollToTopOnNavigation({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
}

export default function Portfolio() {
  return (
    <>
      <div id="hero-container">
        <div
          id="main"
          className="dark:bg-gradient-to-r dark:from-[#243B55] dark:to-[#141E30] transition-all ease-in duration-300"
        ></div>

        <div
          id="main-content"
          className="flex flex-col bg-[#FFE4E87d] dark:bg-gradient-to-r dark:from-[#243B55] dark:to-[#141E30] min-h-screen justify-between "
        >
          <Header />
          <ScrollToTopOnNavigation>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/*" element={<BlogRenderer />} />
              <Route path="*" exact={true} element={<Error404 />} />
            </Routes>
            <Footer />
          </ScrollToTopOnNavigation>
          <ChatBot />
        </div>
      </div>
    </>
  );
}
