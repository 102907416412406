function Browserframe({ comp }) {
  return (
    <div className="min-h-96 min-w-[70%] flex flex-col rounded-xl  overflow-hidden bg-[#ffffff]">
      <div className="h-10 w-full bg-[#222222] flex justify-start items-center flex-row gap-2">
        <div className=" flex flex-row gap-1.5 mx-3">
          <span className="h-2.5 w-2.5 rounded-full bg-[#FC625D]"></span>
          <span className="h-2.5 w-2.5 rounded-full bg-[#FDBC40]"></span>
          <span className="h-2.5 w-2.5 rounded-full bg-[#35CD4B]"></span>
        </div>
        <div className="h-[2rem] min-w-[4.5rem] rounded-t-md  bg-[#ffffff] self-end flex flex-row justify-between items-center px-2">
          <div className="min-w-[2.5rem] h-2 bg-[#6d6d6d57] rounded-full"></div>
          <svg
            width="9"
            height="10"
            viewBox="0 0 9 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.08586 4.99998L0.292969 2.20709L1.70718 0.792877L4.50008 3.58577L7.29297 0.792877L8.70718 2.20709L5.91429 4.99998L8.70718 7.79288L7.29297 9.20709L4.50008 6.4142L1.70718 9.20709L0.292969 7.79288L3.08586 4.99998Z"
              fill="#6d6d6d57"
            ></path>
          </svg>
        </div>
      </div>
      {comp}
    </div>
  );
}
export default Browserframe;
