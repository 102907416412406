import "./App.css";
import Portfolio from "./Portfolio";
import { ThemeProvider } from "./Context/ThemeContext";
import { BrowserRouter as Router } from "react-router-dom";
import { ChatbotProvider } from "./Context/ChatBotContext";
import ErrorBoundary from "./Components/ErrorBoundary";
import { useNavigate } from "react-router-dom";

const ErrorBoundaryWrapper = ({ children }) => {
  const navigate = useNavigate();
  return <ErrorBoundary navigate={navigate}>{children}</ErrorBoundary>;
};

function App() {
  return (
    <Router>
      <ErrorBoundaryWrapper>
        <ThemeProvider>
          <ChatbotProvider>
            <Portfolio />
          </ChatbotProvider>
        </ThemeProvider>
      </ErrorBoundaryWrapper>
    </Router>
  );
}

export default App;
