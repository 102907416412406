export const cross = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.41425 6.00025L11.7072 1.70725C12.0982 1.31625 12.0982 0.68425 11.7072 0.29325C11.3162 -0.09775 10.6843 -0.09775 10.2933 0.29325L6.00025 4.58625L1.70725 0.29325C1.31625 -0.09775 0.68425 -0.09775 0.29325 0.29325C-0.09775 0.68425 -0.09775 1.31625 0.29325 1.70725L4.58625 6.00025L0.29325 10.2933C-0.09775 10.6843 -0.09775 11.3162 0.29325 11.7072C0.48825 11.9022 0.74425 12.0002 1.00025 12.0002C1.25625 12.0002 1.51225 11.9022 1.70725 11.7072L6.00025 7.41425L10.2933 11.7072C10.4883 11.9022 10.7442 12.0002 11.0002 12.0002C11.2562 12.0002 11.5122 11.9022 11.7072 11.7072C12.0982 11.3162 12.0982 10.6843 11.7072 10.2933L7.41425 6.00025Z"
      className="svelte-18sdb5t"
    ></path>
  </svg>
);

export const send = (
  <svg
    height="24"
    version="1.1"
    viewBox="0 0 24 24"
    width="24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs2" />
    <g id="g2058" transform="translate(0.01024069,-410.64611)">
      <path
        fill="currentColor"
        d="m 1.99075,420.60497 c -0.0179,0.54935 0.30654,1.14996 0.87145,1.41007 l 6.6733,3.07149 3.01902,6.67608 c 0.25617,0.56658 0.85532,0.89478 1.40456,0.87975 0.54924,-0.0151 1.1287,-0.37594 1.35344,-0.95571 l 6.57803,-16.96642 c 0.22514,-0.58088 0.047,-1.23606 -0.34251,-1.62689 -0.43046,-0.46849 -1.10806,-0.53267 -1.6269,-0.348 L 2.95196,419.2543 c -0.6898,0.29516 -0.94072,0.75433 -0.96121,1.35067 z m 2.78699,0.0911 14.86029,-5.69828 -5.75492,14.84787 -2.67927,-5.92202 c -0.0993,-0.21922 -0.27436,-0.39526 -0.49301,-0.49584 z"
        id="path2023"
      />
    </g>
  </svg>
);
