import React, { Suspense, lazy } from "react";
import { dracula } from "react-code-blocks";
import PageLoader from "./PageLoader";

const LazyCopyBlock = lazy(() =>
  import("react-code-blocks").then((module) => ({ default: module.CopyBlock }))
);

const CodeView = ({ code }) => {
  return (
    <div
      className="px-5 md:px-0 mt-10"
      style={{ fontSize: "clamp(1rem, 0.8852rem + 0.2898vw, 1.375rem)" }}
    >
      <Suspense fallback={<PageLoader />}>
        <LazyCopyBlock
          className="rounded-none"
          language={"jsx"}
          text={code}
          showLineNumbers={true}
          theme={dracula}
          wrapLines={true}
          codeBlock
        />
      </Suspense>
    </div>
  );
};

export default CodeView;
