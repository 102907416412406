import React from "react";
import useSlideAnimation from "../Hooks/useSlideAnimation";

const star = (
  <svg
    width="100"
    height="100"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="-ml-2"
  >
    <g clipPath="url(#clip0_116_139)">
      <path
        d="M100 0L105.94 94.0603L200 100L105.94 105.94L100 200L94.0603 105.94L0 100L94.0603 94.0603L100 0Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_116_139"
        x1="20.5"
        y1="16"
        x2="100"
        y2="200"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ACAAFF" /> <stop offset="1" stopColor="#C0E8FF" />
      </linearGradient>
      <clipPath id="clip0_116_139">
        <rect width="200" height="200" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Hero = ({ hero, description = null, hideStar = false }) => {
  let heroAnimation = useSlideAnimation(0, "big");
  let descriptionAnimation = useSlideAnimation(0.2, "small");
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-row gap-2 justify-center">
        {!hideStar && (
          <div className="md:block hidden self-end mr-10">{star}</div>
        )}
        <div
          style={heroAnimation}
          className="bg-blue-900 text-white py-8 text-center flex flex-col"
        >
          {hero.map((item, index) => (
            <h1
              key={index}
              className="hero text-light-primary-text dark:text-dark-primary-text text-[6rem] font-bold font-hero leading-[100%] tracking-tighter"
            >
              {item}
            </h1>
          ))}
        </div>
        {!hideStar && <div className="md:block hidden self-start">{star}</div>}
      </div>

      {description && (
        <div
          style={descriptionAnimation}
          className="px-2 text-light-secondary-text dark:text-dark-secondary-text text-lg md:text-xl self-center max-w-screen-md text-center"
        >
          {description}
        </div>
      )}
    </div>
  );
};

export default Hero;
