import { useState, useLayoutEffect } from "react";

const useSlideAnimation = (delay = 0, animate, direction = "in") => {
  const [animationStyle, setAnimationStyle] = useState({
    visibility: direction === "in" ? "hidden" : "visible",
    transition: `visibility 0s linear ${delay}s`,
    animation: undefined,
  });

  useLayoutEffect(() => {
    const animateStyle = () => {
      if (direction === "in") {
        switch (animate) {
          case "small":
            return "slideInDownSmall";
          case "big":
            return "slideInDownBig";
          default:
            return "slideInDownSmall";
        }
      } else if (direction === "out") {
        switch (animate) {
          case "small":
            return "slideOutUpSmall";
          case "big":
            return "slideInUpBig";
          default:
            return "slideOutUpSmall";
        }
      }
    };

    const timerId = setTimeout(() => {
      setAnimationStyle((prevStyle) => ({
        ...prevStyle,
        visibility: direction === "in" ? "visible" : "hidden",
        animation: `0.3s ease-out 0s 1 normal none running ${animateStyle()}`,
        transition: "visibility 0s linear 0s",
      }));
    }, delay * 1000);

    return () => clearTimeout(timerId);
  }, [delay, animate, direction]);

  return animationStyle;
};

export default useSlideAnimation;
