const PageLoader = () => {
  return (
    <div className="h-full flex items-center">
      <div class="loader">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
    </div>
  );
};

export default PageLoader;
