import { useState, useEffect, useRef, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useChatbot } from "./Context/ChatBotContext";

const NavItem = ({ label, onClick, isSelected, id }) => (
  <Link
    to={label.toLowerCase()}
    id={id}
    onClick={onClick}
    role="button"
    aria-label={isSelected ? `Selected ${label}` : label}
    className={`p-1.5 cursor-pointer rounded-full min-w-20 flex justify-center items-center z-10 opacity-60 dark:opacity-100 dark:text-white/70 ${
      isSelected ? "" : ""
    }`}
  >
    {label}
  </Link>
);

export default function Header() {
  const location = useLocation();
  const { isChatbotEnabled, enableChatbot, disableChatbot } = useChatbot();
  const navItems = useMemo(() => ["Home", "About", "Blog"], []);
  const [selectedItem, setSelectedItem] = useState(null);
  const activeMenuRef = useRef(null);
  const [navItemUnderlineData, setNavItemUnderlineData] = useState(null);

  useEffect(() => {
    const arr = location.pathname.split("/");
    const firstIndexValue = arr[1];
    const lowercaseNavItems = navItems.map((item) => item.toLowerCase());
    const index = lowercaseNavItems.indexOf(firstIndexValue);
    setSelectedItem(index !== -1 ? index : 0);
    if (index === selectedItem) {
      activeMenuRef.current = document.getElementById(`yourNavItemID_${index}`);
    }
  }, [location.pathname, navItems, selectedItem]);

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      setNavItemUnderlineData({
        left: activeMenuRef.current?.offsetLeft ?? 10,
        width: activeMenuRef.current?.clientWidth ?? 0,
      });
    }, 100);
    return () => clearTimeout(timerId);
  }, [selectedItem]);

  const isValidRoute = useMemo(() => {
    const lowercaseNavItems = navItems.map((item) => item.toLowerCase());
    const currentPath = location.pathname.slice(1);
    return (
      currentPath === "" ||
      lowercaseNavItems.includes(currentPath) ||
      currentPath.startsWith("blog/")
    );
  }, [location.pathname, navItems]);

  return (
    <header className="m-8 mb-10 w-full flex justify-center items-center select-none sticky top-[2rem] z-40 ">
      <div className="text-light-secondary-text dark:text-dark-secondary-text w-auto h-auto p-2.5 bg-light-backdrop dark:bg-[#7d7d7d45] backdrop-saturate-30 backdrop-blur-md rounded-full flex flex-row xl:gap-8 2xl:gap-12 lg:gap-6 md:gap-4 sm:gap-2 gap-1 justify-between transition-all duration-200 ease-linear relative">
        {navItems.map((item, index) => (
          <NavItem
            key={index}
            label={item}
            id={`yourNavItemID_${index}`}
            onClick={(e) => {
              activeMenuRef.current = e.target;
              handleItemClick(index);
            }}
            isSelected={selectedItem === index}
          />
        ))}
        <button
          onClick={isChatbotEnabled ? disableChatbot : enableChatbot}
          className="p-1.5 cursor-pointer rounded-full min-w-20 flex justify-center items-center z-10 opacity-60 dark:opacity-100 dark:text-white/70 "
        >
          {"Contact"}
        </button>
        {isValidRoute && navItemUnderlineData && (
          <span
            className={`absolute left-12 right-12 bg-light-primary dark:bg-dark-primary min-w-20  rounded-full h-[2.3rem] z-0 transition-all duration-200 ease-in-out`}
            style={{
              left: navItemUnderlineData.left,
              width: navItemUnderlineData.width,
            }}
          />
        )}
      </div>
    </header>
  );
}
