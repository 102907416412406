import { useChatbot } from "../Context/ChatBotContext";
import { cross, send } from "../svg";
import useSlideAnimation from "../Hooks/useSlideAnimation";
import { useState, useEffect, Fragment, useRef } from "react";
import OpenAI from "openai";
import useMediaQuery from "../Hooks/useMediaQuery";

const Typing = () => {
  return (
    <div className="typing">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

function extractUrls(text) {
  // eslint-disable-next-line
  const urlRegex = /\bhttps?:\/\/\S+?\.[a-zA-Z]+(?=[^\w\/]|$)/g;
  return text.match(urlRegex) || [];
}

function renderMessageWithUrls(message) {
  const urls = extractUrls(message.content);
  let content = message.content.replace(/【.*?】/g, "");

  urls.forEach((url) => {
    content = content.replace(
      url,
      `${url}<br /><a target="_blank" rel="noopener noreferrer" class="underline underline-offset-2" href="${url}"> [ Link ] </a>`
    );
  });

  return content.split("\n").map((text, index) => (
    <Fragment key={index}>
      <span dangerouslySetInnerHTML={{ __html: text }}></span>
      <br />
    </Fragment>
  ));
}

const Message = ({ message }) => {
  return (
    <div
      style={{ textAlign: message.isUser ? "right" : "left" }}
      className="mx-3"
    >
      <div
        style={{ overflowWrap: "anywhere" }}
        className={`${
          message.isWaiting ? "w-20 h-10 flex items-baseline pl-5" : ""
        } p-4 rounded-2xl text-light-primary-text dark:text-dark-secondary-text ${
          message.isUser
            ? "bg-[#432f2f17] dark:bg-[#948fdc33]"
            : "bg-[#ffffff] dark:bg-dark-primary"
        }`}
      >
        {!message.isWaiting && renderMessageWithUrls(message)}
        {message.isWaiting && <Typing />}
      </div>
    </div>
  );
};
class MessageDto {
  isUser;
  content;

  constructor(isUser, content) {
    this.isUser = isUser;
    this.content = content;
  }
}

const PreTextButtons = ({ onClick, text }) => {
  return (
    <button
      className="cursor-pointer w-full text-center bg-light-primary-text dark:bg-dark-primary-text px-3 py-2.5 rounded-full"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

const ChatArea = () => {
  const [isWaiting, setIsWaiting] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [thread, setThread] = useState(null);
  const [openai, setOpenai] = useState(null);
  const lastMessageRef = useRef(null);
  const inputRef = useRef(null);
  const isBelowBreakpoint = useMediaQuery(768);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, lastMessageRef]);

  useEffect(() => {
    setMessages([
      {
        content: "Hi",
        isUser: false,
      },
      {
        content:
          "I'm Dilan's AI Bot. I'm here to help you with any questions you might have about Dilan",
        isUser: false,
      },
    ]);
  }, []);

  const initChatBot = async () => {
    const openai = new OpenAI({
      apiKey: "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",
      dangerouslyAllowBrowser: true,
    });

    const thread = await openai.beta.threads.create();

    setOpenai(openai);
    setThread(thread);
  };

  const createNewMessage = (content, isUser) => {
    const newMessage = new MessageDto(isUser, content);
    return newMessage;
  };

  const handleSendMessage = async () => {
    messages.push(createNewMessage(input, true));
    setMessages([...messages]);
    setInput("");
    setMessages((prev) => [
      ...prev,
      {
        content: "",
        isUser: false,
        isWaiting: true,
      },
    ]);
    setIsWaiting(true);

    await openai.beta.threads.messages.create(thread.id, {
      role: "user",
      content: input,
    });

    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: "asst_HfKz1U6Hu79Bck9RAWxXKV0y",
    });

    let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

    while (response.status === "in_progress" || response.status === "queued") {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    }

    setIsWaiting(false);

    const messageList = await openai.beta.threads.messages.list(thread.id);
    setMessages(messages.filter((msg) => !msg.isWaiting));

    const lastMessage = messageList.data
      .filter(
        (message) => message.run_id === run.id && message.role === "assistant"
      )
      .pop();

    if (lastMessage) {
      setMessages([
        ...messages,
        createNewMessage(lastMessage.content[0]["text"].value, false),
      ]);
    }
  };

  useEffect(() => {
    initChatBot();
  }, []);

  const handleMessageChange = (event) => {
    setInput(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      !isWaiting && input && isBelowBreakpoint && inputRef?.current.blur();
      !isWaiting && input && handleSendMessage();
    }
  };

  return (
    <div className="w-full h-full flex flex-col relative overflow-auto">
      <div className="h-[75%] overflow-auto flex flex-col mt-3 gap-1.5">
        {messages.map((message, index) => (
          <div
            key={index}
            ref={index === messages.length - 1 ? lastMessageRef : null}
            style={{ alignSelf: message.isUser ? "flex-end" : "flex-start" }}
          >
            <Message message={message} />
          </div>
        ))}
      </div>
      <div className="absolute bottom-0 h-auto w-full bg-gray-200 p-4 flex flex-col gap-3">
        <div className="flex flex-row gap-4 w-full text-[#fffffff0] dark:text-[#000000]">
          <PreTextButtons
            onClick={() => {
              setMessages((prev) => [
                ...prev,
                {
                  content: "Just saying hello!",
                  isUser: true,
                },
              ]);
              setTimeout(() => {
                setMessages((prev) => [
                  ...prev,
                  {
                    content: "Hi there, hope you are having a good day!",
                    isUser: false,
                  },
                ]);
              }, 1000);
            }}
            text="Just saying hello!"
          />
          <PreTextButtons
            onClick={() => {
              setMessages((prev) => [
                ...prev,
                {
                  content: "Hire me",
                  isUser: true,
                },
              ]);
              setTimeout(() => {
                setMessages((prev) => [
                  ...prev,
                  {
                    content:
                      "Thank you for consideration. Please reach out via call or email using the contact details provided. Phone: +91 8105528922, Email: bopannamj@gmail.com",
                    isUser: false,
                  },
                ]);
              }, 1000);
            }}
            text="Hire me"
          />
        </div>

        <div className="relative">
          <input
            type="text"
            ref={inputRef}
            value={input}
            onChange={handleMessageChange}
            onKeyDown={handleKeyPress}
            placeholder="How can I help you?"
            className="w-full md:p-2.5 p-3 border-2 dark:placeholder:text-[#ffffffca] dark:text-[#fffffff4] text-[#000000] border-light-primary-text dark:border-dark-primary-text bg-light-backdrop dark:bg-dark-backdrop rounded-md outline-none pr-12"
          />
          <div
            className={`absolute ${
              isWaiting ? "opacity-50 cursor-not-allowed" : ""
            } dark:text-[#fffffff0] right-3 top-1/2 transform -translate-y-1/2 cursor-pointer`}
            onClick={(_) => !isWaiting && input && handleSendMessage()}
          >
            {send}
          </div>
        </div>
      </div>
    </div>
  );
};

const ChatBot = () => {
  const { isChatbotEnabled, disableChatbot } = useChatbot();
  const slideIn = useSlideAnimation(0, "big", "in");
  const slideOut = useSlideAnimation(0, "big", "out");
  const slideAnimation = isChatbotEnabled ? slideIn : slideOut;

  const containerStyle = isChatbotEnabled === null ? undefined : slideAnimation;
  const containerClasses = isChatbotEnabled === null ? "invisible" : "visible";

  return (
    <div
      style={containerStyle}
      className={`${containerClasses} flex flex-col fixed z-50 md:bottom-10 md:right-10 right-0 bottom-0 transition ease-in duration-300 shadow-xl h-full w-full md:h-[40.5rem] md:w-96 backdrop-blur-2xl backdrop-saturate-150 md:rounded-xl md:border border-light-primary-text dark:border-dark-primary-text bg-light-backdrop dark:bg-dark-backdrop overflow-hidden`}
    >
      <div className="bg-light-primary-text dark:bg-dark-primary-text flex items-center p-4 leading-4 flex-row justify-between">
        <div className="flex flex-col text-[#fffffff0] dark:text-[#000000] ml-5">
          <div className="font-bold text-base">{"Dilan Bot"}</div>
          <div>{"Ask me anything"}</div>
        </div>
        <div
          className="text-[#fffffff0] dark:text-[#000000] p-4 hover:bg-light-primary/20 focus-visible:bg-light-primary/20 hover:dark:bg-dark-primary/10 rounded-xl cursor-pointer"
          onClick={disableChatbot}
          role="button"
          aria-label="Close Chatbot"
          tabIndex={0}
        >
          {cross}
        </div>
      </div>
      <ChatArea />
    </div>
  );
};

export default ChatBot;
