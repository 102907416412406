import Hero from "./Hero";
import { Link } from "react-router-dom";
import useSlideAnimation from "../Hooks/useSlideAnimation";

const BlogCard = ({ blog, index }) => {
  const slideIn = useSlideAnimation((index + 1) / 10, "big");
  return (
    <Link
      style={slideIn}
      to={`/blog/${blog.fileName}`}
      role="button"
      tabIndex={0}
      aria-label={blog.title}
      className="flex w-full h-auto px-12 py-8 bg-[#fbb6b030] hover:bg-[#fffffe] dark:hover:bg-[#505a6d] transition-colors duration-100 ease-in dark:bg-[#394151] flex-col gap-2 rounded-xl cursor-pointer"
    >
      <div
        style={{ fontSize: "clamp(1rem, .9235rem + .3265vw, 1.25rem)" }}
        className="font-body font-bold text-light-primary-text dark:text-dark-primary-text"
      >
        {blog.title}
      </div>
      <div
        style={{ fontSize: "clamp(.875rem, .7985rem + .3265vw, 1.125rem)" }}
        className="font-body opacity-70 text-light-primary-text dark:text-dark-primary-text"
      >
        {blog.description}
      </div>
    </Link>
  );
};

const Blog = () => {
  const blogPosts = [
    {
      title: "Web Optimization",
      description:
        "How to Supercharge Your Website: The Ultimate Guide to Optimization",
      fileName: "web-optimization",
    },
  ];
  return (
    <div className="w-full md:w-6/12 px-0 flex justify-center items-center flex-col gap-3">
      <Hero
        hero={["Blog."]}
        hideStar={true}
        description={
          "Explore software ideas, insights, and creations. Join my development journey."
        }
      />

      <div className="w-full flex flex-col gap-6 my-20 px-4 md:px-0">
        {blogPosts.map((item, index) => (
          <BlogCard key={index} blog={item} index={index} />
        ))}
      </div>
    </div>
  );
};

export default Blog;
