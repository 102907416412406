import React, { createContext, useState, useContext } from "react";

const ChatbotContext = createContext();

export const ChatbotProvider = ({ children }) => {
  const [isChatbotEnabled, setIsChatbotEnabled] = useState(null);

  const enableChatbot = () => {
    setIsChatbotEnabled(true);
  };

  const disableChatbot = () => {
    setIsChatbotEnabled(false);
  };

  return (
    <ChatbotContext.Provider
      value={{
        isChatbotEnabled,
        enableChatbot,
        disableChatbot,
      }}
    >
      {children}
    </ChatbotContext.Provider>
  );
};

export const useChatbot = () => {
  return useContext(ChatbotContext);
};
