import Hero from "./Hero";
import CardLayout from "./CardLayout";
import Browserframe from "./BrowserFrame";
import useSlideAnimation from "../Hooks/useSlideAnimation";

const projects1 = [
  {
    name: "Rescript-Sentry",
    type: "Npm Library",
    github: "https://github.com/dilanbopanna/sentry-rescript",
    live: "https://www.npmjs.com/package/@rescript-sentry/react",
    comp: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 222 66"
        width="500"
        height="239"
      >
        <path
          d="M29,2.26a4.67,4.67,0,0,0-8,0L14.42,13.53A32.21,32.21,0,0,1,32.17,40.19H27.55A27.68,27.68,0,0,0,12.09,17.47L6,28a15.92,15.92,0,0,1,9.23,12.17H4.62A.76.76,0,0,1,4,39.06l2.94-5a10.74,10.74,0,0,0-3.36-1.9l-2.91,5a4.54,4.54,0,0,0,1.69,6.24A4.66,4.66,0,0,0,4.62,44H19.15a19.4,19.4,0,0,0-8-17.31l2.31-4A23.87,23.87,0,0,1,23.76,44H36.07a35.88,35.88,0,0,0-16.41-31.8l4.67-8a.77.77,0,0,1,1.05-.27c.53.29,20.29,34.77,20.66,35.17a.76.76,0,0,1-.68,1.13H40.6q.09,1.91,0,3.81h4.78A4.59,4.59,0,0,0,50,39.43a4.49,4.49,0,0,0-.62-2.28Z M124.32,28.28,109.56,9.22h-3.68V34.77h3.73V15.19l15.18,19.58h3.26V9.22h-3.73ZM87.15,23.54h13.23V20.22H87.14V12.53h14.93V9.21H83.34V34.77h18.92V31.45H87.14ZM71.59,20.3h0C66.44,19.06,65,18.08,65,15.7c0-2.14,1.89-3.59,4.71-3.59a12.06,12.06,0,0,1,7.07,2.55l2-2.83a14.1,14.1,0,0,0-9-3c-5.06,0-8.59,3-8.59,7.27,0,4.6,3,6.19,8.46,7.52C74.51,24.74,76,25.78,76,28.11s-2,3.77-5.09,3.77a12.34,12.34,0,0,1-8.3-3.26l-2.25,2.69a15.94,15.94,0,0,0,10.42,3.85c5.48,0,9-2.95,9-7.51C79.75,23.79,77.47,21.72,71.59,20.3ZM195.7,9.22l-7.69,12-7.64-12h-4.46L186,24.67V34.78h3.84V24.55L200,9.22Zm-64.63,3.46h8.37v22.1h3.84V12.68h8.37V9.22H131.08ZM169.41,24.8c3.86-1.07,6-3.77,6-7.63,0-4.91-3.59-8-9.38-8H154.67V34.76h3.8V25.58h6.45l6.48,9.2h4.44l-7-9.82Zm-10.95-2.5V12.6h7.17c3.74,0,5.88,1.77,5.88,4.84s-2.29,4.86-5.84,4.86Z"
          transform="translate(11, 11)"
          fill="#362d59"
        ></path>
      </svg>
    ),
  },
  {
    name: "Airbnb Clone",
    type: "WebPage",
    github: "https://github.com/dilanbopanna/airbnb-clone",
    live: "https://airbnb-bopanna.netlify.app/",
    comp: (
      <Browserframe
        comp={
          <img src="/airbnb.png" className="max-h-[32rem]" alt="airbnb-clone" />
        }
      />
    ),
  },
];

const projects2 = [
  {
    name: "Planetzy",
    type: "WebPage",
    github: "https://github.com/dilanbopanna/planetzy",
    live: "https://dilanbopanna.github.io/planetzy/",
    comp: (
      <Browserframe
        comp={
          <img src="/planetzy.png" className="max-h-[20rem]" alt="planetzy" />
        }
      />
    ),
  },
  {
    name: "Rescript Decode",
    type: "VS Code Extension",
    github: "https://github.com/dilanbopanna/rescript-decode-vscode",
    live: "https://marketplace.visualstudio.com/items?itemName=dilanBopanna.rescript-decode",
    comp: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="300"
        height="300"
        viewBox="0 0 250 250"
        fill="none"
        className="sm:ml-[10rem] md:[18rem] lg:ml[20rem] ml-[5rem]"
      >
        <div
          xmlns=""
          script-content="d2luZG93Lk9wdGljRmlsZXM9e3VybDoiY2hyb21lLWV4dGVuc2lvbjovL2hvZGlsYWRsZWZkcGNiZW1uYmJjcGNsYm1rbmtpYWVtLyIsaWQ6ImhvZGlsYWRsZWZkcGNiZW1uYmJjcGNsYm1rbmtpYWVtIixpbWFnZXM6e3N1bmdsYXNzZXM6ImNocm9tZS1leHRlbnNpb246Ly9ob2RpbGFkbGVmZHBjYmVtbmJiY3BjbGJta25raWFlbS9pbWcvc3VuZ2xhc3Nlcy5wbmciLHNvZnRibHVyOiJjaHJvbWUtZXh0ZW5zaW9uOi8vaG9kaWxhZGxlZmRwY2JlbW5iYmNwY2xibWtua2lhZW0vaW1nL3NvZnRibHVyLnBuZyIsdG91Y2FuOiJjaHJvbWUtZXh0ZW5zaW9uOi8vaG9kaWxhZGxlZmRwY2JlbW5iYmNwY2xibWtua2lhZW0vaW1nL3RvdWNhbi5wbmcifX0="
        />
        <div
          xmlns=""
          script-content="CiAgIHNldEludGVydmFsKCgpID0+IHtsZXQgYT1kb2N1bWVudC5ldmFsdWF0ZSgiLy9kaXZbdGV4dCgpPSdDYW1lcmEgaXMgc3RhcnRpbmcnXSIsZG9jdW1lbnQsbnVsbCxYUGF0aFJlc3VsdC5GSVJTVF9PUkRFUkVEX05PREVfVFlQRSxudWxsKS5zaW5nbGVOb2RlVmFsdWU7aWYoYSl7YS5pbm5lclRleHQ9IlBsdWdpbnMgYmVpbmcgaW5zdGFsbGVkIn19LCA1MDAp"
        />
        <script
          xmlns=""
          src="chrome-extension://hodiladlefdpcbemnbbcpclbmknkiaem/dependencies/tfjs.js"
          type="text/javascript"
          id="optic-control-0"
        />
        <g filter="url(#filter0_i)">
          <path
            d="M0 80.0864C0 46.7186 6.38451e-06 30.0439 8.49552 18.3416C11.2413 14.5639 14.5639 11.2412 18.3417 8.49549C30.0322 -1.91535e-05 46.7186 0 80.0814 0H169.912C203.28 0 219.954 -1.91535e-05 231.657 8.49549C235.434 11.2402 238.755 14.563 241.498 18.3416C249.998 30.0372 249.998 46.7186 249.998 80.0864V169.914C249.998 203.281 249.998 219.956 241.498 231.658C238.756 235.436 235.435 238.758 231.657 241.499C219.961 250 203.28 250 169.912 250H80.0814C46.7186 250 30.0389 250 18.3417 241.499C14.563 238.757 11.2402 235.435 8.49552 231.658C6.38451e-06 219.963 0 203.281 0 169.914V80.0864Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <g filter="url(#filter1_di)">
          <path
            d="M169.41 121.016C185.803 121.016 199.093 107.726 199.093 91.3333C199.093 74.9401 185.803 61.6509 169.41 61.6509C153.017 61.6509 139.728 74.9401 139.728 91.3333C139.728 107.726 153.017 121.016 169.41 121.016Z"
            fill="white"
          />
        </g>
        <path
          d="M67.416 89.6876C67.416 80.2648 67.416 75.5518 69.2571 71.9467C70.8672 68.7756 73.4421 66.1971 76.6111 64.5826C80.2112 62.7516 84.9242 62.7517 94.3519 62.7517L115.854 60.6602L117.915 62.7517V172.151C117.915 179.992 117.346 183.517 116.064 186.606C115.218 188.648 113.978 190.504 112.415 192.066C110.852 193.629 108.997 194.869 106.954 195.714C103.863 196.996 100.509 197.394 92.6699 197.394C84.8288 197.394 80.9057 197.394 77.8162 196.112C75.774 195.267 73.9184 194.027 72.3554 192.464C70.7923 190.902 69.5524 189.046 68.7064 187.004C67.4244 183.913 67.4244 179.99 67.4244 172.151L67.416 89.6876Z"
          fill="black"
          fillOpacity="0.2"
        />
        <g filter="url(#filter2_i)">
          <path
            d="M65.3176 87.5824C65.3176 78.1597 65.3176 73.4466 67.1587 69.8416C68.7688 66.6704 71.3438 64.092 74.5127 62.4775C78.1128 60.6465 82.8258 60.6465 92.2535 60.6465H115.817V170.045C115.817 177.887 115.817 181.81 114.535 184.899C113.689 186.941 112.449 188.797 110.886 190.359C109.323 191.922 107.467 193.162 105.425 194.007C102.334 195.289 98.411 195.289 90.5716 195.289C82.7304 195.289 78.8074 195.289 75.7178 194.007C73.6756 193.162 71.8201 191.922 70.257 190.359C68.6939 188.797 67.454 186.941 66.608 184.899C65.326 181.808 65.326 177.885 65.326 170.045L65.3176 87.5824Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_i"
            x="0"
            y="-1.67367"
            width="249.998"
            height="251.674"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-1.67367" />
            <feGaussianBlur stdDeviation="1.67367" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
            id="filter1_di"
            x="136.38"
            y="56.6299"
            width="64.8321"
            height="66.5058"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="2.11999" dy="2.11999" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-3.34735" dy="-5.02102" />
            <feGaussianBlur stdDeviation="4.23998" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.909804 0 0 0 0 0.309804 0 0 0 0 0.309804 0 0 0 0.25 0"
            />
            <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
          </filter>
          <filter
            id="filter2_i"
            x="61.968"
            y="55.6255"
            width="53.849"
            height="139.664"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-3.34959" dy="-5.02102" />
            <feGaussianBlur stdDeviation="4.23998" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.909804 0 0 0 0 0.309804 0 0 0 0 0.309804 0 0 0 0.25 0"
            />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
          </filter>
          <linearGradient
            id="paint0_linear"
            x1="124.999"
            y1="0"
            x2="215.066"
            y2="241.846"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E84F4F" />
            <stop offset="0.408823" stopColor="#DB4646" />
            <stop offset="0.998911" stopColor="#CB3939" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
];

const workProjects = [
  {
    name: "Hyperswitch",
    type: "SDK",
    github: "",
    live: "https://hyperswitch.io/",
    comp: (
      <img
        src="/hyperswitch.png"
        className="max-h-[28rem] rounded-xl"
        alt="hyperswitch"
      />
    ),
  },
  {
    name: "Merchant Dashboard",
    type: "Dashboard",
    github: "",
    live: "https://portal.juspay.in/?redirect=ops-dashboard",
    comp: (
      <Browserframe
        comp={
          <img
            src="/juspay_dashboard.png"
            className="max-h-[32rem]"
            alt="juspay-dashboard"
          />
        }
      />
    ),
  },
];

export default function Home() {
  let projectAni = useSlideAnimation(0.4, "big");

  return (
    <div className="flex flex-col gap-2 justify-center items-center">
      <Hero
        hero={["Hi. I'm Dilan.", "A Software Dev."]}
        description={
          "Passionate software engineer creating engaging, accessible, and user-centric digital experiences."
        }
      />
      <div className="mt-20 mb-16" style={projectAni}>
        <CardLayout
          left={40}
          right={60}
          cardColor={["#d891f2", "#bddff9"]}
          contents={projects1}
        />
        <CardLayout
          left={60}
          right={40}
          cardColor={["#77ad95", "#f7bf97"]}
          contents={projects2}
        />
      </div>
      <Hero
        hero={["Work"]}
        hideStar={true}
        description={
          "Glimpse into the array of projects and products I contributed to during my tenure with past companies."
        }
      />
      <div className="my-20" style={projectAni}>
        <CardLayout
          left={40}
          right={60}
          cardColor={["#bddff9", "#ffffff"]}
          contents={workProjects}
        />
      </div>
    </div>
  );
}
