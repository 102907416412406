import { useState, useEffect } from "react";

export default function useMediaQuery(breakpoint) {
  const [matches, setMatches] = useState(window.innerWidth < breakpoint);

  useEffect(() => {
    const updateMatches = () => {
      setMatches(window.innerWidth < breakpoint);
    };

    window.addEventListener("resize", updateMatches);

    // Initial setup
    updateMatches();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateMatches);
    };
  }, [breakpoint]);

  return matches;
}
