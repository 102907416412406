import React from "react";

const Button = ({ onClick, text }) => {
  return (
    <button
      className="min-w-24 h-12 bg-light-secondary-text dark:bg-dark-primary text-light-primary dark:text-dark-primary-text rounded-md"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;
